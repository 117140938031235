import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import Home from "./Components/Home";
import TrackPayment from './Components/TrackPayment';
import QualifyingBikes from './Components/QualifyingBikes';
import FAQ from './Components/FAQ';
import Contact from './Components/Contact';
import PaymentsForm from './Components/PaymentsForm';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from '@apollo/client';
import PaymentsMessage from './Components/paymentMessage';
import MessageReceived from './Components/messageReceived';

function App() {

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_NODE_URL + ':4000/graphql',
    headers: { 'Apollo-Require-Preflight': 'true' }
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        token: token ? `${token}` : "",
      }
    }
  });
  const wsLink = new GraphQLWsLink(createClient({
    url: process.env.REACT_APP_NODE_URL_WSS + ':4000/graphql',
    options: {
      reconnect: true,

    }
  }));

  const errorlink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      console.log(response);
    }
  });

  const httpLinkErrHandling = ApolloLink.from([
    errorlink,
    httpLink,
  ])

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    authLink.concat(httpLinkErrHandling)
  )

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache()
  });


  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path='/trackpayment' element={<TrackPayment />} />
            <Route exact path='/qualifyingbikes' element={<QualifyingBikes />} />
            <Route exact path='/faq' element={<FAQ />} />
            <Route exact path='/contactus' element={<Contact />} />
            <Route exact path='/paymentsform' element={<PaymentsForm />} />
            <Route exact path='/payment-status' element={<PaymentsMessage />} />
            <Route exact path='/message-status' element={<MessageReceived />} />
          </Routes>
        </Router>
      </div>
    </ApolloProvider>
  );
}
export default App;
