import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { Container, Col, Row, Button, InputGroup } from "react-bootstrap";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import "./DahonWarrantyRebate.css";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";

const Home = () => {
    return (
        <section>
        <Navigation />
        <Header />
            <Container className="py-4">
                <Row className="justify-content-center py-4">
                    <Col md={6}>
                        <h2 className="text-center display-6">Register your new Dahon Bicycle Warranty and receive a $25 Rebate.</h2>
                    </Col>
                </Row>
                <Row className="text-center p-4 justify-content-evenly">
                    <Col md={5} className='p-4 mt-2 border'>
                        <h5 className="py-4">Start my Warranty Registration Rebate</h5>
                        <a href="/paymentsform" className="btn dahon-btn-primary">Start Rebate</a>
                    </Col>
                    <Col md={5} className='p-4 mt-2 border'>
                        <h5 className="py-4">Track Rebate Status</h5>
                        <a href="/trackpayment" className="btn dahon-btn-primary">Track Rebate</a>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </section>
    );
}

export default Home;
