export const cities = [
    {
        name: 'Alabama',
        value: 'AL'
    },
    {
        name: 'Alaska',
        value: 'AK'
    },
    {
        name: 'Arizona',
        value: 'AZ'
    },
    {
        name: 'Arkansas',
        value: 'AR'
    },
    {
        name: 'California',
        value: 'CA'
    },
    {
        name: 'Colorado',
        value: 'CO'
    },
    {
        name: 'Connecticut',
        value: 'CT'
    },
    {
        name: 'Delaware',
        value: 'DE'
    },
    {
        name: 'District of Columbia',
        value: 'DC'
    },
    {
        name: 'Florida',
        value: 'FL'
    },
    {
        name: 'Georgia',
        value: 'GA'
    },
    {
        name: 'Hawaii',
        value: 'HI'
    },
    {
        name: 'Idaho',
        value: 'ID'
    },
    {
        name: 'Illinois',
        value: 'IL'
    },
    {
        name: 'Indiana',
        value: 'IN'
    },
    {
        name: 'Iowa',
        value: 'IO'
    },
    {
        name: 'Kansas',
        value: 'KS'
    },
    {
        name: 'Kentucky',
        value: 'KY'
    },
    {
        name: 'Louisiana',
        value: 'LA'
    },
    {
        name: 'Maine',
        value: 'ME'
    },
    {
        name: 'Maryland',
        value: 'MD'
    },
    {
        name: 'Massachusetts',
        value: 'MA'
    },
    {
        name: 'Michigan',
        value: 'MI'
    },
    {
        name: 'Minnesota',
        value: 'MN'
    },
    {
        name: 'Mississippi',
        value: 'MS'
    },
    {
        name: 'Missouri',
        value: 'MS'
    },
    {
        name: 'Montana',
        value: 'MT'
    },
    {
        name: 'Nebaraska',
        value: 'NE'
    },
    {
        name: 'Nevada',
        value: 'NV'
    },
    {
        name: 'New Hampshire',
        value: 'NH'
    },
    {
        name: 'New Jersey',
        value: 'NJ'
    },
    {
        name: 'New Mexico',
        value: 'NM'
    },
    {
        name: 'New York',
        value: 'NY'
    },
    {
        name: 'North Carolina',
        value: 'NC'
    },
    {
        name: 'North Dakota',
        value: 'ND'
    },
    {
        name: 'Ohio',
        value: 'OH'
    },
    {
        name: 'Oklahoma',
        value: 'OK'
    },
    {
        name: 'Oregon',
        value: 'OR'
    },
    {
        name: 'Pennsylvania',
        value: 'PA'
    },
    {
        name: 'Rhode Island',
        value: 'RI'
    },
    {
        name: 'South Carolina',
        value: 'SC'
    },
    {
        name: 'South Dakota',
        value: 'SD'
    },
    {
        name: 'Tennessee',
        value: 'TN'
    },
    {
        name: 'Texas',
        value: 'TX'
    },
    {
        name: 'Utah',
        value: 'UT'
    },
    {
        name: 'Vermont',
        value: 'VT'
    },
    {
        name: 'Virginia',
        value: 'VA'
    },
    {
        name: 'Washington',
        value: 'WA'
    },
    {
        name: 'West Virginia',
        value: 'WV'
    },
    {
        name: 'Wisconsin',
        value: 'WI'
    },
    {
        name: 'Wyoming',
        value: 'WY'
    },

]

export const patterns = {
    specialCharacterCheckPatternWithSpace: /^[a-z0-9\s_.-]+$/i,
    specialCharacterCheckPattern: /^[a-z0-9_.-]+$/i,
    phoneNumberCheckPattern: /^(?:\d*)$/,
    passwordCheck:/^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-]).{8,}$/,
    zipCheck:/^[0-9]*$/,
    onlyChar:/^[a-zA-Z ]*$/g
}

export const rewardTypes={
    physical:"visareward",
    virtual:"virtualvisa",
    check:"check",
}