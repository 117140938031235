import React, { useState, useEffect } from 'react';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import { gql, useQuery, useMutation } from '@apollo/client'
import { message, Radio } from 'antd';
import Footer from './Footer';
import './DahonWarrantyRebate.css';
import { Form, Input, Select } from 'antd';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { Link, useLocation } from 'react-router-dom';
import Header from './Header';
import Navigation from './Navigation';







const MessageReceived = (state) => {


    const { t } = useTranslation();
    const { Option } = Select;
    const location = useLocation();

    return (
        <>
        <Navigation />
        <Header />
            <Container>
                <Row className='mt-5'>
                    <Col className='text-center'>
                        <h4>Your message has been received</h4>
                        <p>Thank you for contacting customer service. A representative will reach out to you within 48 hours.</p>
                    </Col>
                </Row>
                <Row className='my-5'>
                </Row>
            </Container>
            <Footer />
        </>
    );
}

export default MessageReceived;