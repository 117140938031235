import React, { Fragment, useState } from 'react';
import { Nav, Navbar, Container, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';
import './Navigation.css';
import { Select } from 'antd';


const Navigation = () => {
  const { Option } = Select;
  const [selected, setSelected] = useState(localStorage.getItem('i18nextLng'))


  const handleChange = (value) => {
    localStorage.setItem('i18nextLng', value)
    setSelected(value)
    i18next.changeLanguage(value)
  };

  return (
    <>
      <Navbar expand="lg" bg="white" className='pb-4'>
        <Container className='border-bottom pb-3'>
          <Navbar.Brand to="/"><a href='/' ><Image fluid src='images/Dahon_Cropped_260x.jpg' className='nav-logo' /></a></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/qualifyingbikes">Qualifying Bikes</NavLink>
              <NavLink to="/faq">FAQ</NavLink>
              <NavLink to="/contactus">Contact Us</NavLink>
              <a href="https://bpsmyaccount.com/home" target={'_blank'}>My Visa Card</a>
              <Navbar.Collapse className='ms-2'>
              </Navbar.Collapse>
            </Nav>

          </Navbar.Collapse>

        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;