import React, {Fragment} from 'react';
import 'antd/dist/antd.css';
import { Container, Col, Row, Accordion } from 'react-bootstrap';
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import './DahonWarrantyRebate.css';
import { useTranslation } from 'react-i18next';


const FAQ = () => {

    const {t}= useTranslation();

    return (
        <section>
            <Navigation />
            <Header />
            <Container className='my-5'>
                <Row>
                    <Col>
                        <h2 className="display-6 pb-4">{t('Frequently_Asked_Questions')}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Accordion alwaysOpen flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t('faq1')}</Accordion.Header>
                                <Accordion.Body>{t('ans1')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{t('faq2')}</Accordion.Header>
                                <Accordion.Body>{t('ans2')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>{t('faq3')}</Accordion.Header>
                                <Accordion.Body>{t('ans3')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>{t('faq4')}</Accordion.Header>
                                <Accordion.Body>{t('ans4')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>{t('faq5')}</Accordion.Header>
                                <Accordion.Body>{t('ans5')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>{t('faq6')}</Accordion.Header>
                                <Accordion.Body><a href='#' className='dahon-link'>{t('Click_here_hyperlink')}</a> {t('ans6')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>{t('faq7')}</Accordion.Header>
                                <Accordion.Body>{t('ans7')} <a href='/contactus' className='dahon-link'>{t('contact_us_hyperlink')}</a> {t('page')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>{t('faq8')}</Accordion.Header>
                                <Accordion.Body>{t('ans8')}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>{t('faq9')}</Accordion.Header>
                                <Accordion.Body>{t('ans9')} <a href='/contactus' className='dahon-link'>{t('contact_us_hyperlink')}</a> {t('page')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>{t('faq10')}</Accordion.Header>
                                <Accordion.Body>{t('ans10')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>{t('faq11')}</Accordion.Header>
                                <Accordion.Body>{t('ans11')} <a href='/contactus' className='dahon-link'>{t('contact_us_hyperlink')}</a> {t('page')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>{t('faq12')}</Accordion.Header>
                                <Accordion.Body>{t('ans12')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="12">
                                <Accordion.Header>{t('faq13')}</Accordion.Header>
                                <Accordion.Body>{t('ans13')} <a href='/contactus' className='dahon-link'>{t('contact_us_hyperlink')}</a> {t('page')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="14">
                                <Accordion.Header>{t('faq14')}</Accordion.Header>
                                <Accordion.Body>
                                    <p><span className='fw-bold'>{t('ans141Status')}</span> {t('ans141')}</p>
                                    <p><span className='fw-bold'>{t('ans142Status')}</span> {t('ans142')}</p>
                                    <p><span className='fw-bold'>{t('ans143Status')}</span> {t('ans143')}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="15">
                                <Accordion.Header>{t('faq15')}</Accordion.Header>
                                <Accordion.Body>{t('ans15')} <a href='/contactus' className='dahon-link'>{t('contact_us_hyperlink')}</a> {t('page')} {t('ans142')}</Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </section>
    );
}

export default FAQ;