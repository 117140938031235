import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { Container, Col, Row, Button, Image } from "react-bootstrap";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import "./DahonWarrantyRebate.css";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client";

const QualifyingBikes = () => {
    return (
        <section>
        <Navigation />
        <Header />
            <Container className="py-4">
                <Row className="justify-content-center py-4">
                    <Col md={6}>
                        <h2 className="text-center display-6">Qualifying Bikes</h2>
                        <p className="text-center">Offer valid for any new genuine Dahon bike purchased through a certified dealer or Dahon Direct representative.</p>
                    </Col>
                </Row>
                <Row className="justify-content-center py-4">
                    <Col lg={6} className="mx-auto">
                        <Image fluid src="/images/Bikes.png" className="mx-auto d-block" />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </section>
    );
}

export default QualifyingBikes;
