import React, { useState, useEffect } from 'react';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import { gql, useQuery, useMutation } from '@apollo/client'
import { message, Radio } from 'antd';
import Footer from './Footer';
import './DahonWarrantyRebate.css';
import { Form, Input, Select } from 'antd';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { Link, useLocation } from 'react-router-dom';
import Header from './Header';
import Navigation from './Navigation';







const PaymentsMessage = (state) => {


    const { t } = useTranslation();
    const { Option } = Select;
    const location = useLocation();

    return (
        <>
        <Navigation />
        <Header />
            <Container>
                <Row className='mt-5'>
                    <Col className='text-center'>

                        <h4>{location.state.message}</h4>
                        <p><a href="/trackpayment" className='dahon-link'>Click here</a> to track your payment status.</p>

                    </Col>
                </Row>
                <Row className='my-5'>
                </Row>
            </Container>
            <Footer />
        </>
    );
}

export default PaymentsMessage;