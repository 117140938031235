import { InboxOutlined } from "@ant-design/icons";
import AWS from "aws-sdk";
import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { message, Radio } from "antd";
import Footer from "./Footer";
import "./DahonWarrantyRebate.css";
import { Form, Input, Select, Space, Checkbox, Upload, Alert } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { cities, patterns, rewardTypes } from "../Constants";
import ErrorList from "antd/lib/form/ErrorList";
import Navigation from "./Navigation";
import Header from "./Header";

const PaymentsForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const redirect = useNavigate();
  const { Option } = Select;
  const [selected, setSelected] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const [token, setToken] = useState();
  const [virtualVisaDisable, setvirtualVisaDisable] = useState(false);
  const [check, setCheck] = useState(false);
  const [disable, setDisable] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [getParam, setParam] = useSearchParams();
  const [serialNum, setSerialNum] = useState();
  const [serialNumberConfirmed, setSerialNumberValidated] = useState();
  const [paymentReemed, setpaymentReemed] = useState(false);
  const [fileUploadName, setFileUploadName] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const { Dragger } = Upload;
  const propsUpload = {
    multiple: true,
    maxCount: 1,
    accept: "image/png, image/jpeg, application/pdf",
    onRemove() {
      setFileUploadName();
    },
    customRequest({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onRemove,
      onProgress,
      onSuccess,
      withCredentials,
    }) {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
        region: "us-west-2",
      });

      const S3 = new AWS.S3();
      let cleanedFileName = file.name.replace(/[^a-zA-Z0-9\.]/g, "");
      let finalFileName = "dahon_rebate_receipt" + "/" + cleanedFileName;
      const objParams = {
        ACL: "",
        Bucket: "dahonfiles",
        Key: finalFileName,
        Body: file,
        ContentType: file.type,
      };

      S3.putObject(objParams)
        .on("httpUploadProgress", function ({ loaded, total }) {
          onProgress(
            {
              percent: Math.round((loaded / total) * 100),
            },
            file
          );
        })
        .send(function (err, data) {
          if (err) {
            console.log(err);
          } else {
            onSuccess(setFileUploadName(finalFileName));
            // setUploadFiles(uploadFilesCopy);
            // setButtonLabel("Next");
            // setButtonDisable(false);
            // tabToNextSection(null, setSection7);
            // setUserUploadedFiles(userUploadedFilesCopy);
            // setHighlightFields10(false);
            // if (highlightFields11 || highlightFields12) {
            //   setSection7(true);
            // } else if (highlightFields13) {
            //   setSection9(true);
            // }
            //console.log("SEND FINISHED");
          }
        });
    },
  };

  const checkPaymentType = (amount) => {
    amount > 1000 && amount <= 2500
      ? setvirtualVisaDisable(true)
      : amount > 2500
      ? setvirtualVisaDisable(true) && setCheck(true)
      : setvirtualVisaDisable(false) && setCheck(false);
    if (amount <= 500) {
      setCheck(true);
    }
  };

  const AddRebate = gql`
    mutation addRebate(
      $bikeModel: String
      $bikeSerialNumber: String!
      $customerFirstName: String!
      $customerLastName: String!
      $customerAddress1: String!
      $customerAddress2: String
      $customerCity: String!
      $customerState: String!
      $customerZip: String!
      $customerEmail: String!
      $customerPhoneNumber: String!
      $rewardType: RewardType!
      $receiptFilename: String!
      $surveyAnswer1: [String]
      $surveyAnswer2: [String]
      $surveyAnswer3: [String]
      $surveyAnswer4: [String]
      $surveyAnswer5: [String]
    ) {
      addRebate(
        input: {
          bikeModel: $bikeModel
          bikeSerialNumber: $bikeSerialNumber
          customerFirstName: $customerFirstName
          customerLastName: $customerLastName
          customerAddress1: $customerAddress1
          customerAddress2: $customerAddress2
          customerCity: $customerCity
          customerState: $customerState
          customerZip: $customerZip
          customerEmail: $customerEmail
          customerPhoneNumber: $customerPhoneNumber
          rewardType: $rewardType
          receiptFilename: $receiptFilename
          surveyAnswer1: $surveyAnswer1
          surveyAnswer2: $surveyAnswer2
          surveyAnswer3: $surveyAnswer3
          surveyAnswer4: $surveyAnswer4
          surveyAnswer5: $surveyAnswer5
        }
      ) {
        bikeModel
        bikeSerialNumber
        customerFirstName
        customerLastName
        customerAddress1
        customerAddress2
        customerCity
        customerState
        customerZip
        customerEmail
        customerPhoneNumber
        rewardType
        receiptFilename
        survey {
          surveyAnswer1
          surveyAnswer2
          surveyAnswer3
          surveyAnswer4
          surveyAnswer5
        }
      }
    }
  `;

  // const getPaymentData = useQuery(GET_PAYMENTFORM_DATA, {
  //     variables: {
  //         token: token
  //     }
  // });

  // const [updateIndividualPayment] = useMutation(UPDATE_PAYMENT)

  const [rebateadd] = useMutation(AddRebate);

  const serialnumbercheck = gql`
    query ($serialNumber: String!) {
      serialNumberCheck(serialNumber: $serialNumber)
    }
  `;
  const [checkserialnumber] = useLazyQuery(serialnumbercheck);

  const serialCheck = () => {
    const serialnumber = form.getFieldValue("serialnumber");
    setSerialNum(serialnumber);
  };
  useEffect(() => {
    const validationSerialNum = setTimeout(async () => {
      if (serialNum !== null || serialNum !== undefined) {
        const res = await checkserialnumber({
          variables: {
            serialNumber: serialNum,
          },
        });
        let validate = await res.data.serialNumberCheck;
        setSerialNumberValidated(validate);
        form.validateFields(["serialnumber"]);
      }
    }, 1500);
    return () => clearTimeout(validationSerialNum);
  }, [serialNum, checkserialnumber, form, serialNumberConfirmed]);
  const paymentFormHandler = async () => {
    const {
      paymentType,
      bikemodel,
      serialnumber,
      customerFirstName,
      customerLastName,
      customerEmail,
      phone,
      mailingAddress1,
      mailingAddress2,
      city,
      state,
      zip,
      survey5,
      survey4,
      survey3,
      survey2,
      survey1,
    } = form.getFieldValue();
    try {
      const res = await rebateadd({
        variables: {
          bikeModel: bikemodel,
          bikeSerialNumber: serialnumber,
          customerFirstName: customerFirstName,
          customerLastName: customerLastName,
          customerAddress1: mailingAddress1,
          customerAddress2: mailingAddress2,
          customerCity: city,
          customerState: state,
          customerZip: zip,
          customerEmail: customerEmail,
          customerPhoneNumber: phone,
          rewardType: paymentType,
          receiptFilename: fileUploadName,
          surveyAnswer1: survey1,
          surveyAnswer2: survey2,
          surveyAnswer3: survey3,
          surveyAnswer4: survey4,
          surveyAnswer5: survey5,
        },
      });
      if (res) {
        redirect("/payment-status", {
          state: { message: "Your rebate form was successfully submitted." },
        });
      }
    } catch (error) {
      setAlertMessage(error.message);
      // message.error({
      //   content: error.errors.message,
      //   style: {
      //     marginTop: "10vh",
      //     fontSize: "1vw",
      //   },
      //   duration: 10,
      // });
    }
  };

  const handleChange = (value) => {
    localStorage.setItem("i18nextLng", value);
    setSelected(value);
    i18next.changeLanguage(value);
  };

  const verifyHandler = () => {
    const {
      bikemodel,
      serialnumber,
      customerFirstName,
      customerLastName,
      customerEmail,
      phone,
      mailingAddress1,
      city,
      state,
      zip,
      receiptFilename,
    } = form.getFieldValue();
    if (
      mailingAddress1 === undefined ||
      state === undefined ||
      city === undefined ||
      zip === undefined ||
      bikemodel === undefined ||
      serialnumber === undefined ||
      customerFirstName === undefined ||
      customerLastName === undefined ||
      customerEmail === undefined ||
      phone === undefined ||
      fileUploadName === undefined ||
      receiptFilename === undefined
    ) {
      form.validateFields();
      form.setFields();
    }else {
      setDisable(true);
      setHidden(false);
    }
      // form.setFields([
      //   {
      //     name: "receiptFilename",
      //     errors: ["Receipt is required"],
      //   },
      // ]);
  };

  return (
    <section>
      <Navigation />
      <Header />
      <Container>
        <Row className="text-center py-4">
          <Col>
            <h2>Register your new Dahon</h2>
            <p className="mb-0">
              Complete your warranty registration and submit your rebate
              request.
            </p>
            <p>
              Upon completion, you will receive an email from us providing you a
              tracking number.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form
              form={form}
              layout="vertical"
              requiredMark="optional"
              name="dynamic_rule"
              onFinish={paymentFormHandler}
            >
              <h5>Bike Information</h5>
              <Row className="mb-4 border-bottom pb-2">
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    label={"Bike Model"}
                    name={"bikemodel"}
                    rules={[
                      {
                        required: true,
                        message: "Bike model is required",
                      },
                      
                    ]}
                  >
                    <Input disabled={disable}/>
                  </Form.Item>
                  <span className="small text-secondary">
                    Reply with "not sure" if you do not know the model of your
                    bike.
                  </span>
                </Col>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    label={"Bike Serial Number"}
                    name={"serialnumber"}
                    rules={[
                      () => ({
                        validator(_, value) {
                          let serialcheck = serialNumberConfirmed;
                          if (serialNumberConfirmed === false) {
                            return Promise.reject("Invalid Serial Number");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                      {
                        required: true,
                        message: "Serial number is required",
                      },
                    ]}
                  >
                    <Input onKeyUp={serialCheck} disabled={disable}/>
                  </Form.Item>
                  <span className="small text-secondary">
                    A letter followed by a ten digit number found on the head
                    tube or bottom bracket of your Dahon bike.
                  </span>
                </Col>
              </Row>
              <Row className="border-bottom pb-5">
                <Col>
                  <Form.Item
                    label={"Receipt Upload"}
                    name={"receiptFilename"}
                    rules={[
                      {
                        required: true,
                        message: "Receipt is required",
                      },
                    ]}
                  >
                    <Dragger {...propsUpload} disabled={disable}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p>
                        Click or drag file to this area to bike purchase receipt
                      </p>
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <h5>Customer Information</h5>
                  <Row>
                    <Col md={6}>
                      <Form.Item
                        as={Col}
                        className="mb-3"
                        label={t("first_name")}
                        name={"customerFirstName"}
                        rules={[
                          {
                            required: true,
                            message: "First name is required",
                          },
                          {
                            pattern: patterns.onlyChar,
                            message: "Special character is not allowed",
                          },
                        ]}
                      >
                        <Input disabled={disable}/>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        as={Col}
                        className="mb-3"
                        label={t("last_name")}
                        name={"customerLastName"}
                        rules={[
                          {
                            required: true,
                            message: "Last name is required",
                          },
                          {
                            pattern: patterns.onlyChar,
                            message: "Special character is not allowed",
                          },
                        ]}
                      >
                        <Input disabled={disable}/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    className="mb-3"
                    label={t("address1")}
                    name={"mailingAddress1"}
                    rules={[
                      {
                        required: true,
                        message: "Address is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    className="mb-3"
                    label={t("address2")}
                    name={"mailingAddress2"}
                    requiredMark={hidden}
                  >
                    <Input disabled={disable}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    className="mb-3"
                    label={t("city")}
                    name={"city"}
                    rules={[
                      {
                        required: true,
                        message: "City is required",
                      },
                      {
                        pattern: patterns.onlyChar,
                        message: "Special character is not allowed",
                      },
                    ]}
                  >
                    <Input disabled={disable}/>
                  </Form.Item>
                </Col>
                <Col md={3} xs={6}>
                  <Form.Item
                    as={Col}
                    className="mb-3"
                    label={t("state")}
                    name={"state"}
                    rules={[
                      {
                        required: true,
                        message: "State is required",
                      },
                    ]}
                  >
                    <Select
                      disabled={disable}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {cities.map((city, index) => {
                        return (
                          <Option key={index} value={city.value}>
                            {city.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={3} xs={6}>
                  <Form.Item
                    name="zip"
                    label={t("zip")}
                    rules={[
                      {
                        required: true,
                        pattern: patterns.zipCheck,
                        message: "Value should only contain numbers.",
                      },
                    ]}
                  >
                    <Input disabled={disable}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mb-4 pb-2 border-bottom">
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    label={t("email")}
                    name={"customerEmail"}
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                      {
                        type: "email",
                        message: "please enter valid email address",
                      },
                    ]}
                  >
                    <Input disabled={disable}/>
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    as={Col}
                    label={t("phone")}
                    name={"phone"}
                    rules={[
                      {
                        required: true,
                        message: "Phone number is required",
                      },
                      {
                        pattern: patterns.phoneNumberCheckPattern,
                        message: "Value should contain just number.",
                      },
                      {
                        min: 10,
                        message: "Phone number should be of 10 digit long.",
                      },
                      {
                        max: 10,
                        message: "Phone number should be of 10 digit long.",
                      },
                    ]}
                  >
                    <Input disabled={disable}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="required-field">
                  <h5>Select Your Payment Type:</h5>
                </Col>
              </Row>
              <Row className="mb-4 pb-2 border-bottom">
                <Col>
                  <Form.Item
                    name={"paymentType"}
                    rules={[
                      {
                        required: true,
                        message: "Payment type is required",
                      },
                    ]}
                  >
                    <Radio.Group disabled={disable}>
                      <Row className="justify-content-between">
                        <Col md className="my-2">
                          <Radio
                            value={rewardTypes.virtual}
                            className="border p-4 w-100 h-100 "
                          >
                            <Row className="justify-content-between">
                              <Col xs={7}>
                                <h6>Dahon Virtual Visa Rewards Card**</h6>
                                <p>
                                  Receive e-code by email. For online purchases
                                  only
                                </p>
                              </Col>
                              <Col xs={5}>
                                <Image
                                  fluid
                                  src="images/DahonVirtualVisa.png"
                                />
                              </Col>
                            </Row>
                          </Radio>
                        </Col>
                        <Col md className="my-2">
                          <Radio
                            value={rewardTypes.physical}
                            className="border p-4 w-100 h-100"
                          >
                            <Row className="justify-content-between">
                              <Col xs={7}>
                                <h6>Dahon Visa Rewards Card**</h6>
                                <p>Receive a physical card by mail</p>
                              </Col>
                              <Col xs={5}>
                                <Image
                                  fluid
                                  src="images/DahonPhysicalVisa.png"
                                />
                              </Col>
                            </Row>
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>
                    Survey:{" "}
                    <span className="small fs-6 fw-normal">(Optional)</span>
                  </h5>
                  <p>
                    Help us make our bikes even better by answering a few
                    questions.
                  </p>
                </Col>
              </Row>
              <Row className="mb-4 pb-2 border-bottom">
                <Col>
                  <h6>1. How did you hear about Dahon?</h6>
                  <Form.Item name={"survey1"}>
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={"Print advertisement"}>
                          Print advertisement
                        </Radio>
                        <Radio value={"Digital advertisement"}>
                          Digital advertisement
                        </Radio>
                        <Radio value={"Friend or family"}>
                          Friend or family
                        </Radio>
                        <Radio value={"Online Search"}>Online Search</Radio>
                        <Radio value={5}>
                          Other
                          <Input
                            style={{
                              width: 200,
                              marginLeft: 10,
                            }}
                          />
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <h6>2. Have you purchased a folding bike before?</h6>
                  <Form.Item name={"survey2"}>
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={"Yes"}>Yes</Radio>
                        <Radio value={"No"}>No</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <h6>
                    3. Do you own additional bikes? (check all that apply)
                  </h6>
                  <Form.Item name={"survey3"}>
                    <Checkbox.Group>
                      <Space direction="vertical">
                        <Checkbox value={"Yes, a mountain bike"}>
                          Yes, a mountain bike
                        </Checkbox>
                        <Checkbox value={"Yes, a roadbike"}>
                          Yes, a roadbike
                        </Checkbox>
                        <Checkbox value={"Yes, a gravel or cyclocross bike"}>
                          Yes, a gravel or cyclocross bike
                        </Checkbox>
                        <Checkbox value={"no"}>no</Checkbox>
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                  <h6>
                    4. How will you use your Dahon? (check all that apply)
                  </h6>
                  <Form.Item name={"survey4"}>
                    <Checkbox.Group>
                      <Space direction="vertical">
                        <Checkbox value={"Commuting to work or school"}>
                          Commuting to work or school
                        </Checkbox>
                        <Checkbox value={"Short trips and errands"}>
                          Short trips and errands
                        </Checkbox>
                        <Checkbox value={"Recreation/exercise"}>
                          Recreation/exercise
                        </Checkbox>
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                  <h6>5. How often do you ride your bike?</h6>
                  <Form.Item name={"survey5"}>
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={"Once or twice a month"}>
                          Once or twice a month
                        </Radio>
                        <Radio value={"Once or twice a week"}>
                          Once or twice a week
                        </Radio>
                        <Radio value={"Three to five days a week"}>
                          Three to five days a week
                        </Radio>
                        <Radio value={"Almost every day"}>
                          Almost every day
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="py-4 border-bottom">
                <Col>
                  <p>
                    Note: This rebate cannot be combined with other Dahon North
                    America offers.
                  </p>
                  <p>
                    Eligibility Requirements: Offer available to new purchasers
                    of Dahon bicycles who submit this Rebate Form according to
                    Rebate Terms below.
                  </p>
                  <p>
                    * Rebate Terms: Only available to customers who purchase a
                    new Dahon bicycle from an authorized dealer or agent. Rebate
                    form must be completed and submitted to Dahon within 90 days
                    of the purchase date, along with required documentation.
                    Allow 3-7 weeks for processing. Late submissions and those
                    submitted without proper documentation and signatures will
                    be subject to delay or cancellation. Void where prohibited.
                  </p>
                  <p>
                    ** Reward Card: Dahon Card / Virtual card is issued by
                    Pathward, N.A., Member FDIC, pursuant to a license from Visa
                    U.S.A. Inc. No cash access or recurring payments. Card can
                    be used everywhere Visa debit cards are accepted. Virtual
                    card can be used everywhere Visa debit cards are accepted
                    online, or phone/mail orders. Card/Virtual card valid for up
                    to 6/24 months; unused funds will forfeit after the valid
                    thru date. Terms and conditions apply
                  </p>
                </Col>
              </Row>
              <Row hidden={!hidden} className="py-5 justify-content-center">
                <Col md={3}>
                  <Button
                    onClick={() => {
                      form
                        .validateFields()
                        .then((resolve) => {
                          if (resolve) {
                            verifyHandler();
                          }
                        })
                        .catch((err) => {
                          window.scrollTo(0, 500);
                        });
                    }}
                    disabled={paymentReemed}
                    className="dahon-btn-primary w-100"
                  >
                    {t("submit")}
                  </Button>
                </Col>
              </Row>
              <Row className="py-5 justify-content-center" hidden={hidden}>
                <Col md={3}>
                  <Button
                    className="dahon-btn-secondary w-100"
                    onClick={() => {
                      setHidden(true);
                      setDisable(false);
                    }}
                  >
                    {t("back")}
                  </Button>
                </Col>
                <Col md={3}>
                  <Button type="submit" className="dahon-btn-primary w-100">
                    {t("submit")}
                  </Button>
                </Col>
              </Row>
              <Row
                className="mb-2"
                hidden={alertMessage !== undefined ? false : true}
              >
                <Col md={8}>
                  <Alert message={alertMessage} type="error" />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default PaymentsForm;
