import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Form, InputNumber, message, Input, Button } from 'antd';
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import './DahonWarrantyRebate.css';
import { gql, useLazyQuery } from '@apollo/client';
import { patterns } from '../Constants';
import { useSearchParams } from 'react-router-dom';

const TrackPayment = () => {

    const [form] = Form.useForm();
    const [status, setStatus] = useState();
    const [date, setStatusDate] = useState();
    const [getParam, setParam] = useSearchParams()


    const GET_STATUS = gql`
    query getStatusByTrackingNumber($trackingNumber:Float!){
        getStatusByTrackingNumber(trackingNumber: $trackingNumber) {
          trackedStatus
        }
      }`

    useEffect(() => {
        if (getParam.get('trackingNumber')) {
            form.setFieldsValue({
                trackingNumber: getParam.get('trackingNumber')
            })
            getStatus({ variables: { trackingNumber: +getParam.get('trackingNumber') } })
        }
    }, [])

    const [getStatus] = useLazyQuery(GET_STATUS, {
        onCompleted(status) {
            setStatusDate("")
            if (status.getStatusByTrackingNumber.trackedStatus.includes('.')) {
                let errMes = status.getStatusByTrackingNumber.trackedStatus.split('.')
                setStatus(errMes[0])
                setStatusDate(errMes[1])
            } else {
                setStatus(status.getStatusByTrackingNumber.trackedStatus)
            }
        },
        onError(err) {
            setStatusDate("")
            if (err.message.includes('.')) {
                let errMes = err.message.split('.')
                setStatus(errMes[0])
                setStatusDate(errMes[1])
            } else {
                setStatus(err.message)
            }
        },
        fetchPolicy: 'network-only'
    })


    const handleFormSubmit = () => {
        let tracking = form.getFieldValue().trackingNumber
        getStatus({ variables: { trackingNumber: tracking } })
    }


    return (
      <section>
        <Navigation />
        <Header />
        <Container className="py-4">
          <Row className="justify-content-center py-4 text-center">
            <Col md={6}>
              <h2 className="text-center display-6">
                Track your rebate status
              </h2>
              <p className="mb-0">
                Enter your Rebate Status Tracking Number to see the status of
                your rebate.
              </p>
              <p>
                Your Rebate Status Tracking Number is provided in the
                confirmation email you received for submitting your rebate.
              </p>
            </Col>
          </Row>
        </Container>
        <Container className='my-5 py-4 border'>

          <Row className="py-4 justify-content-start text-center">
            <Col md={6} className="px-4">
            <h6 className='border-bottom pb-2 mb-3'>Tracking Number</h6>
              
              <Form form={form} name="dynamic_rule" onFinish={handleFormSubmit}>
                <Form.Item
                  name="trackingNumber"
                  rules={[
                    {
                      required: true,
                      message:"Tracking Number is required"
                    },
                    {
                      pattern: patterns.phoneNumberCheckPattern,
                      message: "Value should contain only numbers.",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder={"Rebate Status Tracking Number"}
                    className='w-100'
                  />
                </Form.Item>
                <Form.Item>
                    <button
                        type='primary'
                      className="dahon-btn-primary btn-block w-100 p-2"
                    >
                      Submit
                    </button>
                </Form.Item>

              </Form>
            </Col>
            <Col md={6} className="px-4 pt-4 pt-md-0">
            <h6 className='border-bottom pb-2 mb-3'>Current Status</h6>
              <div>{status}</div>
              <div>{date}</div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </section>
    );
}

export default TrackPayment;