import React, { Fragment, useState } from 'react';
import 'antd/dist/antd.css';
import { Container } from 'react-bootstrap';
import './DahonWarrantyRebate.css';


const Header = () => {


    return (
        <section>
            <Container className='header'>
            </Container>
        </section>
    );
}

export default Header;